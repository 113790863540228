import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import HomeScreen from "../components/homescreen/homescreen"
import Intro from "../components/intro/intro"
import Search from "../components/indexSearch/indexSearch"
import News from "../components/indexNews/indexNews"
import Logos from "../components/logosBanner/logosBanner"
import Footer from "../components/footer/footer"
import Sobre from "../components/intro/sobre"
import Equipa from "../components/equipa/equipa"
import Sidebar from "../components/sidebar/sidebar"
import SidebarMobile from "../components/sidebar/mobile"
import Video from '../components/video/video'

const IndexPage = ({ data }) => {
  const breakpoints = useBreakpoint()

  return (
    <Layout>
      <SEO title="Saven" titleTemplate="%s" />
      {!breakpoints.mobile ? (
        <Sidebar white="isWhite" switchMenu="left" />
      ) : (
        <SidebarMobile />
      )}
      <Video/>
      <HomeScreen
        data={data.indexJson.homeScreenImg.childImageSharp.fluid}
        json="homepage"
        saven
      />
      <div id="visit" />
      <Intro data={data.indexJson.intro} json="homepage" />
      <div id="know-saven" />
      <Sobre data={data.savenJson} json="homepage" dark />
      <Search data={data.vinhosJson.docs} dataReg={data.vinhosJson.regionais} />
      <Equipa data={data.equipaJson.equipa} saven light />
      <News data={data.mediaJson.banner.imgbanner.childImageSharp.fluid} />
      <Logos data={data.globalJson.logoGroup.childImageSharp.fluid} />
      <Footer />
    </Layout>
  )
}

export default IndexPage

export const Json = graphql`
  query index {
    indexJson {
      homeScreenImg {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      intro {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    mediaJson {
      banner {
        imgbanner {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    globalJson {
      logoGroup {
        childImageSharp {
          fluid(quality: 70, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    savenJson {
      background {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    vinhosJson {
      docs {
        bairrada {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        dao {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        douro {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        setubal {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        palmela {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        porto {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        tejo {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        verde {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
      }
      regionais {
        alentejo {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        mesa {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        minho {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        setubal {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
        tejo {
          nome
          ano
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          tipo
          regiao
          esgotado_pt
            esgotado_en
            esgotado_fr
        }
      }
    }
    equipaJson {
      equipa {
        img {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1800) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
