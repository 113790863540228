import React from "react"
import Img from "gatsby-image"
import font from "../../fonts/fonts.module.scss"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation, I18nextContext} from 'gatsby-plugin-react-i18next'
import { AnchorLink } from "gatsby-plugin-anchor-links"

import styled from "styled-components"
import { device } from "../device"

const Intro = ({ data, json }) => {
    const breakpoints = useBreakpoint();
    const {t} = useTranslation([json]);

    const activeLanguage = React.useContext(I18nextContext);

    return(
        <StyledIntro>
            <div className="content">
                <h2 className={font.nSR + " introTitle"}>{t('intro.title')}</h2>
                <h3 className={font.nSI + " introSubtitle"}>{t('intro.subtitle')}</h3>
                <p className={font.nSR + " introTxt"} dangerouslySetInnerHTML={{__html:t('intro.text')}}></p>
                <AnchorLink to={activeLanguage.language === activeLanguage.defaultLanguage ? '/#know-saven' : activeLanguage.language + "/#know-saven"} className='know-saven'>
                    <h5 className={font.aGGD + " introLink"}>{t('intro.linkTxt')}</h5>
                </AnchorLink>
            </div>
            {!breakpoints.mobile &&
                <div className="image">
                    <Img fluid={data.img.childImageSharp.fluid} style={{ width: `70%` }} imgStyle={{ objectFit: "contain" }}/>
                </div>
            }
        </StyledIntro>
    )
}

export default Intro

const StyledIntro = styled.div`
    padding: 80px 25px 110px;

    @media ${device.desktop}{
        padding: 40px 25px;
        display: grid;
        grid-template-columns: 2fr 3fr;
        margin-left: 200px;
        margin-top: 15vh;
        padding: unset;
    }

    @media ${device.desktopL}{
        margin-left: 15.65%;
    }

    .content{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: flex-end;
        max-width: 700px;
        margin: 0 auto;

        @media ${device.desktop}{
            max-width: unset;
            margin:unset;
            margin-left: 30%;
            width: 70%;
            margin-bottom: 2vw;
        }

        .introTitle{
            color: #3e3028;
            font-size: 35px;

            @media ${device.desktop}{
                font-size: 46px;
            }
        }

        .introTxt{
            color: #3e3028;
            font-size: 18px;

            @media ${device.desktop}{
                font-size: 20px;
            }
        }

        .introSubtitle{
            color: #fa4f4f;
            margin-bottom: 1.2em;
        }

        .introLink{
            margin-top: 3em;
            text-decoration: underline;
            font-size:13px;

            @media ${device.desktop}{
                font-size: 18px;
            }
        }
    }

    .image{
        padding-left: 3vw;
    }
`
