import React, { useState, useEffect } from "react"
import useEventListener from "../../custom/use-event-listener"
import Img from "gatsby-image"
import { device } from "../device"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {Link, useTranslation} from 'gatsby-plugin-react-i18next'

import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"
import { Button } from "../buttons"

import linkify from "../../custom/linkify"

const Search = ({ data, dataReg }) => {
  const {t} = useTranslation(['homepage'])
  const breakpoints = useBreakpoint();

  const [matches, setMatches] = useState([]);
  const [pesquisa, setPesquisa] = useState({value: ""});
  
  useEffect(() => {
    setPesquisa(document.querySelector("#search"));
  }, [pesquisa, setPesquisa]);
  useEventListener("input", () => searchList(pesquisa.value));

  let listaVinhos = [];
  listaVinhos = listaVinhos.concat(data.bairrada, data.dao, data.douro, data.setubal, data.palmela, data.porto, data.tejo, data.verde, dataReg.alentejo, dataReg.mesa, dataReg.minho, dataReg.setubal, dataReg.tejo);

  function searchList(searchText){
    setMatches(listaVinhos.filter(item => {
      const regex = new RegExp(`${searchText}`, 'gi');
      return item.nome.match(regex);
    }));
    document.querySelector(".notMobile").style.visibility = "unset";
    if(searchText.length===0){
      setMatches([]);
      document.querySelector(".notMobile").style.visibility = "hidden";
    }
  }

  function select(i){
    let search = document.querySelector("#search").value;
    let vinho = [];
    vinho = listaVinhos.filter(item => {
      const regex = new RegExp(`${search}`, 'gi');
      return item.nome.match(regex);
    }); 
    setMatches([vinho[i]]);
  }

  return(
    <StyledSearch>
        <div className="search">
          <h4 className={font.aGGD + " searchTitle"}>{t('indexSearch.title')}</h4>
          <input type="text" name="search" id="search" className={font.nSI + " searchInput"} placeholder={t('indexSearch.searchBtn')}/>
          <div className="mobile">
            <Button regular className="searchBtn"><h5 className={font.nSR}>{t('indexSearch.searchBtn')}</h5></Button>
          </div>
            <div className="notMobile">
              <p className={font.nSI}>{t('indexSearch.otherResult')}</p>
              <Link to={t('indexSearch.catalog.link')}><p className={font.aGGB + " red"}>{t('indexSearch.catalog.linkTxt')}</p></Link>
            </div>
        </div>
        <div className="result">
          {pesquisa && pesquisa.value.length === 0 ? 
            <h2 className={font.nSR + " instructions"}>{t('indexSearch.searchInstructions')}</h2>
            :
            <>
              {matches.length === 1 ?
                <div className="singleResult">
                  {!breakpoints.mobile &&
                    <div className="resultImg">
                      <Img fluid={matches[0].img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }}/>
                    </div>
                  }

                  <div className="resultTxt">
                    <div className="resultTitle">
                      <h2 className={font.nSR + " red vinhoNome"}>{matches[0].nome}</h2>
                      <h2 className={font.nSR + " gray vinhoNome"}>{matches[0].tipo}</h2>
                    </div>
                    {breakpoints.mobile &&
                      <div className="resultImg">
                        <Img fluid={matches[0].img.childImageSharp.fluid} style={{ width: `100%`, height: "100%" }} imgStyle={{ objectFit: "contain" }}/>
                      </div>
                    }
                    <div className="resultMoreInfo">
                      <Link className="knowMoreBtn extraBtns" to={"/vinhos/" + linkify(matches[0].regiao, matches[0].nome)}><h5 className={font.nSR}>{t('indexSearch.knowMoreBtn')}</h5></Link>
                      <Link className="searchMoreBtn extraBtns" to="/vinhos"><h5 className={font.nSR}>{t('indexSearch.searchMoreBtn')}</h5></Link>
                    </div>
                  </div>
                </div>
                :
                <div className="result resultMultiple">
                  {matches.slice(0, 5).map((data, i)=>(
                    <Button styleless onClick={()=>select(i)} key={"results " + i}><p className={font.nSBI}>{data.nome}</p></Button>
                  ))}
                </div>
              }
            </>
          }
        </div>
    </StyledSearch>
  )
}

export default Search

const StyledSearch = styled.div`
    padding: 40px 25px;
    background: #fff8f4;
    color: #3e3028;

    @media ${device.mobile}{
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media ${device.tabletM}{
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding:unset;
      min-height:unset;
    }

    @media ${device.desktop}{
      margin-top: 15vh;
      margin-left: 200px;
    }

    @media ${device.desktopL}{
        margin-left: 15.65%;
    }

    .mobile{
      display:none;
    }

    .notMobile{
      visibility:hidden; 
      text-align: center;
      margin-top:15px;

      @media ${device.tabletM}{
        margin:0;
      }

      p{
        font-size: 15px;

        @media ${device.tabletM}{
          font-size:20px;
        }
      }
    }

    .red{
      color: #fa4f4f;
    }

    .gray{
      color: #d8d8d8;
    }

    .search{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-bottom:25px;

        @media ${device.tabletM}{
          border-right: 1px solid #d8d8d8;
          height: 630px;
          padding: 60px;
          margin:0;
        }

        .searchTitle{
          font-size:15px;
          text-align: center;

          @media ${device.tabletM}{
            font-size: 23px;
          }
        }

        .searchInput{
          text-align: center;
          background: none;
          border: none;
          border-bottom: 1px solid #3e3028;
          outline: none;
          width:100%;
          font-size: 18px;
          margin-top: 25px;

          @media ${device.tabletM}{
            font-size: 50px;
            margin:0;
          }
        }
    }

    .result{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      @media ${device.tabletM}{
        height: 630px;
      }

      .instructions{
        text-align: center;
        width: 80%;
        font-size: 15px;

        @media ${device.tabletM}{
          font-size: 40px;
        }
      }

      .vinhoNome{
        font-size:22px;
        text-align:center;

        @media ${device.tabletM}{
          font-size: 40px;
        }

        @media ${device.desktop}{
          text-align:left;
        }
      }

      button{
        margin: 3px 0;

        @media ${device.tabletM}{
          margin:0;
        }

        p{
          font-size:15px;

          @media ${device.tabletM}{
            font-size:20px;
          }
        }
      }
    }

    .resultMultiple{
      padding: 15% 0;
    }

    .singleResult{
      display: flex;
      height: 100%;
      width: 100%;
      justify-content: space-between;
      flex-direction:column-reverse;
      width: 100%;

      @media ${device.tabletM}{
        flex-direction: row;
      }

      .resultImg{
        height: 300px;
        margin: 25px 0;

        @media ${device.desktop}{
          width: 43%;
          height: 100%;
          margin: 0;
        }
      }

      .resultTxt{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media ${device.desktop}{
          width: 54%;
          padding: 10% 3% 5%;
        }
      }

      .extraBtns{
        width: 327px;
        border: 1px solid #3e3028;
        border-radius: 2px;
        height: 3.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: transparent;
      }

      .resultMoreInfo{
        .knowMoreBtn{
          margin-bottom: 5%;
          width:auto;
        }

        .searchMoreBtn{
          width:auto;
        }
      }
    }
`