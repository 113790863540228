import React, { useState, useRef, useEffect } from "react"
import { I18nextContext } from 'gatsby-plugin-react-i18next' 

import styled from "styled-components"

import VideoLuaCheiaPT from '../../assets/videos/luacheia-video-pt.mp4'
import VideoLuaCheiaEN from '../../assets/videos/luacheia-video-en.mp4'
import CloseButton from '../../images/close-button.svg'
import VideoPoster from '../../images/index/video-banner.png'

const Footer = () => {
  const activeLanguage = React.useContext(I18nextContext)
  const [showModal, setShowModal] = useState(true)
  const videoRef = useRef(null)
  const [scrollLock, setScrollLock] = useState(true)

  useEffect(() => {
    const html = document.querySelector('html')
    if (typeof document !== `undefined`){
      scrollLock ? (html.style.overflow = 'hidden') : (html.style.overflow = 'visible')
    }
  }, [scrollLock])

  const closeVideo = () => {
    videoRef.current.pause()
    setShowModal(false)
    setScrollLock(false)
  }

  return(
    <StyledVideo showModal={showModal}>
        <button onClick={closeVideo}>
          <img src={CloseButton} alt="Fechar" className="close-button"/>
        </button>
        <video 
          ref={videoRef} 
          controls 
          autoPlay 
          muted 
          playsInline 
          loop 
          poster={VideoPoster}
        >
            <source src={activeLanguage.language === 'pt' ? VideoLuaCheiaPT : VideoLuaCheiaEN} type="video/mp4" />
            O Browser não permite a reprodução do video.
        </video>
    </StyledVideo>
  )
}

export default Footer

const StyledVideo = styled.div`
  position:fixed;
  width:100vw;
  height:100vh;
  display: ${props => props.showModal ? 'flex' : 'none'};
  flex-direction:column;
  justify-content:center;
  align-items:center;
  padding:0 15%;
  z-index:9999;
  background-color: #ffffffcc;

  @media only screen and (max-width:1000px){
    padding:0 10%;
  }

  video{
    width:100%;
    height:auto;

    :focus{
      outline:0;
    }
  }

  button{
    width: 2%;
    max-width:35px;
    height: auto;
    margin-bottom: 15px;

    .close-button{
      width:100%;
      height:100%;
    }

    @media only screen and (max-width:800px){
      width: 3%;
    }

    @media only screen and (max-width:400px){
      width: 4.5%;
    }
  }
`