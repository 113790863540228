import React from "react"
import Slider from "react-slick";
import { device } from "../device"

import styled from "styled-components"

import partnerAdega from '../../images/partners/adega-pegos-claros.png'
import partnerAgua from '../../images/partners/agua-alta.png'
import partnerAzul from '../../images/partners/azul-portugal.png'
import partnerCompanhia from '../../images/partners/companhia-lezirias.png'
import partnerDona from '../../images/partners/dona-helena.png'
import partnerKoneser from '../../images/partners/koneser.png'
import partnerFreitas from '../../images/partners/m-j-freitas.png'
import partnerMonte from '../../images/partners/monte-baia.png'
import partnerSamora from '../../images/partners/samora.png'
import partnerVolleraux from '../../images/partners/volleraux.png'


const Logos = () => {

  const settings = {
    dots: false,
    arrows: false,
    autoplay: true,
    infinite: true,
    autoplaySpeed:2000,
    slidesToShow: 7,
    slidesToScroll: 1,
    pauseOnHover: false,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 2,
        }
      }
    ]
  }

  return(
    <StyledLogos>
      <Slider {...settings}>
        <div className='partner'>
          <img src={partnerAdega} className='partner-img' alt='adega'/>
        </div>
        <div className='partner'>
          <img src={partnerAgua} className='partner-img' alt='agua'/>
        </div>    
        <div className='partner'>
          <img src={partnerAzul} className='partner-img' alt='azul'/>
        </div>  
        <div className='partner'>
          <img src={partnerCompanhia} className='partner-img' alt='companhia'/>
        </div>  
        <div className='partner'>
          <img src={partnerDona} className='partner-img' alt='dona'/>
        </div>   
        <div className='partner'>
          <img src={partnerFreitas} className='partner-img' alt='freitas'/>
        </div>  
        <div className='partner'>
          <img src={partnerKoneser} className='partner-img' alt='koneser'/>
        </div>
        <div className='partner'>
          <img src={partnerMonte} className='partner-img' alt='monte'/>
        </div> 
        <div className='partner'>
          <img src={partnerSamora} className='partner-img' alt='samora'/>
        </div>
        <div className='partner'>
          <img src={partnerVolleraux} className='partner-img' alt='volleraux'/>
        </div>
      </Slider>
    </StyledLogos>
  )
}


export default Logos

const StyledLogos = styled.div`
    padding: 30px 0;

    @media ${device.desktop}{
      margin-left: 200px;
      padding: 100px 0;
    }
    
    @media ${device.desktopL}{
        margin-left: 15.65%;
    }

    .slick-list{
      overflow: hidden;
    }

    .slick-track{
      display:flex;
    }

    .partner{
      display: flex !important;
      justify-content: center;
      align-items: center;

      &:focus{
        outline:0;
      }
    }

    .partner-img{
      width:100%;
      max-width:150px;
      height: 100%;
    }
`